import Form from '../../../common/Form';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import TextArea from '../../../common/Form/TextArea';

import Rating from '../../../common/Form/Rating';
import { closeModalAction } from '../../../actions/modal';
import { getTicketDetailsById, siteCompleted } from '../../../actions/tickets';
import config from '../../../config';
import devConfig from '../../../config/env/development';
import prodConfig from '../../../config/env/production';


const formName = 'feRatingForm'
export const FeRating = ({ callOnFormSubmit, ticketId, isLoading, isSiteCompletd, submitButtonText, cancelButtonText, callViewTicket, ticketDetails }) => {


    const defaultValues = {};
    const methods = useForm({ defaultValues, mode: 'all' });

    const dispatch = useDispatch();
    const [rating, setRating] = useState(0);
    const [signOffRating, setSignOffRating] = useState(0);

    const handleClick = (selectedValue) => {
        setRating(selectedValue);
    };
    const handleClick1 = (selectedValue) => {
        setSignOffRating(selectedValue);
    };
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { ticketDetailsById } = useSelector((state) => state.tickets);

    const onSubmit = (formValues) => {
        if (isSiteCompletd) {
            let payload = { ticketId: ticketId, formName, refresh: true };
            if (formValues.work_quality_rating) payload.work_quality_rating = formValues.work_quality_rating;
            if (formValues.work_quality_comment) payload.work_quality_comment = formValues.work_quality_comment;
            if (formValues.sign_off_quality_rating) payload.sign_off_quality_rating = formValues.sign_off_quality_rating;
            if (formValues.sign_off_quality_comment) payload.sign_off_quality_comment = formValues.sign_off_quality_comment;
            if (callViewTicket) payload.callViewTicket = callViewTicket;

            if (ticketDetails && ticketDetails?.assets[0]?.serial_number) payload.serial_number = ticketDetails.assets[0]?.serial_number;

            if (ticketDetailsById && ticketDetailsById?.assets[0]?.serial_number) payload.serial_number = ticketDetailsById.assets[0]?.serial_number;
            const callCnergeeExecuteCheckApi = (config.name === devConfig.name && parseInt(ticketDetails?.fk_project_id) === 1964) || (config.name === prodConfig.name && (parseInt(ticketDetails?.fk_project_id) === 2263 || parseInt(ticketDetails?.fk_project_id) === 2285));
            payload.callCnergeeExecuteCheckApi = callCnergeeExecuteCheckApi;
            dispatch(siteCompleted(payload));
        } else {
            callOnFormSubmit({ ...formValues, formName });
        }
    };

    const { setValue } = methods
    useEffect(() => {
        if (ticketDetailsById && Array.isArray(ticketDetailsById.ratings) && ticketId) {
            let rating = ticketDetailsById.ratings.find((rate) => rate.fk_ticket_id === parseFloat(ticketId))
            if (rating) {
                setValue('work_quality_rating', rating.work_quality_rating)
                setValue('work_quality_comment', rating.work_quality_comment)
                setValue('sign_off_quality_rating', rating.sign_off_quality_rating)
                setValue('sign_off_quality_comment', rating.sign_off_quality_comment)
            }

        }
    }, [ticketDetailsById])
    useEffect(() => {
        if (ticketId) {

            dispatch(getTicketDetailsById({ ticketId }))
        }

    }, [ticketId, dispatch])



    return (
        <>
            <div className='flex justify-center  flex-col'>
                <Form
                    methods={methods}
                    onCancel={() => dispatch(closeModalAction())}
                    onSubmit={onSubmit}
                    submitButtonText={submitButtonText}
                    showSubmit
                    cancelButtonText={cancelButtonText}
                    isLoading={isLoading || formButtonLoading[formName]}
                    submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                >
                    <div className='flex flex-col justify-around p-2'>
                        <div className='flex pt-1 '>
                            <div className='w-4/12'>
                                <Rating shouldUnregister required label='Work Quality Rating' name='work_quality_rating' onChangeHandler={handleClick} />
                            </div>
                            <TextArea
                                className='w-8/12 '
                                label={`${rating > 2 || rating === 0 ? 'Comment (Optional)' : 'Comment'}`}
                                name='work_quality_comment'
                                type={'text'}
                                required={rating <= 2 && rating !== 0}
                            />
                        </div>

                        <div className='flex pt-1'>
                            <div className='w-4/12'>
                                <Rating shouldUnregister required label='Sign Off Quality Rating' name='sign_off_quality_rating' onChangeHandler={handleClick1} />
                            </div>
                            <TextArea
                                className='w-8/12 '
                                label={`${signOffRating > 2 || signOffRating === 0 ? 'Comment (Optional)' : 'Comment'}`}
                                name='sign_off_quality_comment'
                                type={'text'}
                                required={signOffRating <= 2 && signOffRating !== 0}
                            />
                        </div>
                    </div>
                </Form>
            </div>
        </>
    );
};
