import { takeLatest, takeEvery, put, call, all, delay, select, take } from 'redux-saga/effects';
import api from '../apis';
import { user, auth, partners, toast, modal, loading, utils, customer, fe, wallet } from '../types';
import microservices from '../lambdas/microservices';
import lambdas from '../lambdas/apis';
import { guid } from '../utils/utils';
import { getLoggedUser } from '../selectors/users';
import { uploadFileToS3WithSignedUrl } from '../utils/common';
import { CLUSTER, PSP, SUPERVISOR, isCustomer, isCustomerGroup } from '../utils/role';
import { vendorsTab } from '../pages/team/TeamController';
import { isPerson18YearOld } from '../pages/Onboarding/SP/ProfileDetailsForm';

export function* getServiceTypesList(action) {
    try {
        yield put({ type: loading.SERVICETYPE_LIST_LOADING, payload: true });
        const response = yield call(api.services.listAll, {});
        if (response.data.code === 200) {
            let serviceTypesList = [];
            response.data.data.map((service) => {
                return serviceTypesList.push({
                    key: `${service.id}`,
                    value: service.id,
                    label: service.service_name,
                });
            });
            yield put({ type: user.SERVICETYPE_LIST_SUCCESS, payload: serviceTypesList });
        } else {
            yield put({ type: user.SERVICETYPE_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.SERVICETYPE_LIST_FAILURE, payload: error });
    }
    yield put({ type: loading.SERVICETYPE_LIST_LOADING, payload: false });
}

export function* getAllUsecases(action) {
    try {
        yield put({ type: loading.USECASES_LIST_LOADING, payload: true });
        const response = yield call(api.usecases.getAll, {});
        if (response.data.code === 200) {
            let usecases = [];
            response.data.data.forEach((usecase) => {
                if (usecase.is_enabled === 1) {
                    usecases.push({
                        key: `${usecase.id}`,
                        value: usecase.id,
                        label: usecase.use_case_name,
                    });
                }
            });
            yield put({ type: user.USECASES_LIST_SUCCESS, payload: usecases });
        } else {
            yield put({ type: user.USECASES_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.USECASES_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.USECASES_LIST_LOADING, payload: false });
}

export function* getNotification(action) {
    const queryparams = {
        limit: 10,
        offset: action.payload.offset,
    };
    try {
        yield put({ type: loading.IS_NOTIFICATION_LOADING, payload: true });
        const response = yield call(lambdas.notifications.getNotification, {}, {}, queryparams);
        if (response.data.code === 200) {
            yield put({ type: user.GET_NOTIFICATION_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: user.GET_NOTIFICATION_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.GET_NOTIFICATION_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.IS_NOTIFICATION_LOADING, payload: false });
}

export function* getNextNotification(action) {
    const queryparams = {
        limit: 10,
        offset: action.payload.offset,
    };
    try {
        yield put({ type: loading.IS_NEXT_NOTIFICATION_LOADING, payload: true });
        const response = yield call(lambdas.notifications.getNotification, {}, {}, queryparams);
        if (response.data.code === 200) {
            yield put({ type: user.GET_NEXT_NOTIFICATION_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: user.GET_NEXT_NOTIFICATION_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.GET_NEXT_NOTIFICATION_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.IS_NEXT_NOTIFICATION_LOADING, payload: false });
}

export function* readNotification(action) {
    const body = action.payload.notificationId;

    try {
        const response = yield call(lambdas.notifications.readNotification, {}, {}, {}, body);
        if (response.data.code === 200) {
            yield all([
                yield put({ type: auth.USER_PROFILE_REQUEST, payload: { navigate: () => { } } }),
                yield put({ type: user.READ_NOTIFICATION_SUCCESS, payload: response.data.data }),
            ]);
        } else {
            yield put({ type: user.READ_NOTIFICATION_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.READ_NOTIFICATION_FAILURE, payload: { message: error } });
    }
}

export function* updateUserStatus(action) {
    const { userId, status, formName, disabled_duration, msg } = action.payload;
    let queryParams = {};
    if (status === 'disable') queryParams = { disabled_duration, msg };
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.invitations.setEnalbeDisable, {}, { userId, action: status }, queryParams);
        if (response.data.code === 201) {
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: partners.REFRESH_PARTNER_FILTER_REQUEST });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
        } else {
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: user.UPDATE_USER_STATUS_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.UPDATE_USER_STATUS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updatePartnersProfile(action) {
    const { data, customerId, formName, tabName, address_proof, address_proof_back, profile_pic, callDetails, callCheckKycStatus = false } = action.payload;

    const callAddressProof = address_proof || address_proof_back;
    const callProfilePic = profile_pic
    let form = new FormData();
    if (profile_pic && profile_pic.length > 0) form.set('profile_image', profile_pic[0]);
    form.set('user_id', data.user_id);

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
        const response = yield call(api.auth.updateProfile, {}, {}, {}, data);
        if (response.data.code === 201) {
            const promises = [
                yield put({ type: user.UPDATE_PARTNER_PROFILE_SUCCESS, payload: response.data }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Profile Updated Succesfully' } })
            ];
            if (callDetails) {
                yield put({
                    type: user.GET_SP_PROFILE_DETAILS_REQUEST,
                    payload: { id: data.user_id }
                })
            }
            if (callCheckKycStatus) {
                yield put({
                    type: user.CHECK_AND_UPDATE_KYC_STATUS,
                    payload: { adhaar_number: data.adhaar_number, pancard_number: data.pancard_number, userId: data.user_id }
                })
            }
            if (callProfilePic) {
                yield put({
                    type: auth.UPDATE_PROFILE_PIC_REQUEST,
                    payload: { form, profile_pic },
                })
            }
            if (callAddressProof) {
                yield put({
                    type: user.UPLOAD_USER_ID_PROOF_FRONT_BACK_REQUEST,
                    payload: { addressProofType: "Aadhar", addressProofNumber: data.adhaar_number, proofImage: address_proof, formName, fileUrlBack: address_proof_back, userId: data.user_id, refreshUserTable: true, tabName, customerId }
                })
            } else {
                promises.push(yield put({ type: modal.CLOSE_MODAL }));
                if (tabName === 'customer_vendors') {
                    promises.push(yield put({ type: customer.GET_CUSTOMER_VENDORS_LIST_REQUEST, payload: { customer_id: customerId } }));
                } else {
                    promises.push(yield put({ type: partners.REFRESH_PARTNER_FILTER_REQUEST }));
                }
            }
            yield all(promises);
        } else {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: user.UPDATE_PARTNER_PROFILE_FAILURE, payload: { message: 'Oops Something went wrong' } }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: user.UPDATE_PARTNER_PROFILE_FAILURE, payload: { message: error } });
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
    }
    if (!callAddressProof) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
    }
}

export function* promoteUser(action) {
    const { userId, data, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(microservices.user.promoteUser, {}, { userId }, {}, data);
        if (response.data.code === 200) {
            yield put({ type: partners.GET_PARTNER_COUNT_REQUEST })
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } }),
                yield put({ type: partners.REFRESH_PARTNER_FILTER_REQUEST }),
            ]);
        } else {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: user.PROMOTE_USER_FAILURE, payload: { message: 'Oops Something went wrong' } }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } }),
                yield put({ type: partners.GET_PARTNER_COUNT_REQUEST })
            ]);
        }
    } catch (error) {
        yield put({ type: user.PROMOTE_USER_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* demoteUser(action) {
    const { userId, data, role, userType, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(microservices.user.demoteUser, {}, { userId }, {}, data);
        if (response.data.code === 200) {

            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } }),
                yield put({ type: partners.REFRESH_PARTNER_FILTER_REQUEST, }),
                yield put({ type: partners.GET_PARTNER_COUNT_REQUEST })
            ]);
        } else {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: user.DEMOTE_USER_FAILURE, payload: { message: 'Oops Something went wrong' } }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } }),
            ]);
        }
    } catch (error) {
        yield put({ type: user.DEMOTE_USER_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getAllStates(action) {
    try {
        const response = yield call(api.states.listAll, {});
        if (response.data.code === 200) {
            let states = [];
            response.data.data.state_list.map((state) => {
                return states.push({
                    key: `${state.state_id}`,
                    value: state.state_id,
                    label: state.state_name,
                });
            });
            yield put({ type: user.STATES_LIST_SUCCESS, payload: states });
        } else {
            yield put({ type: user.STATES_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.STATES_LIST_FAILURE, payload: { message: error } });
    }
}

export function* getAllCities(action) {
    try {
        const response = yield call(api.cities.getAll, {});
        yield put({ type: loading.SET_ALL_CITY_LIST_LOADING, payload: true });
        if (response.data.code === 200) {
            let cities = [];
            response.data.data.city_list.map((city) => {
                return cities.push({
                    key: `${city.city_id}_${city.fk_state_id}`,
                    value: city.city_id,
                    label: `${city.city_name}, ${city.state.state_name}`,
                });
            });
            yield put({ type: user.CITIES_LIST_SUCCESS, payload: cities });
        } else {
            yield put({ type: user.CITIES_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.CITIES_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_ALL_CITY_LIST_LOADING, payload: true });
}

export function* getAllExistingCities(action) {
    const { servicePartnerId } = action.payload;
    try {
        yield put({ type: loading.SET_EXISTING_CITY_LIST_LOADING, payload: true });
        const response = yield call(api.SP.getExistingCities, {}, { id: servicePartnerId });
        if (response.data.code === 200) {
            let existingCities = [];
            response.data.data.service_partner_existing_location.forEach((city) => {
                if (city.fk_city_id) {
                    return existingCities.push({
                        key: `${city.fk_city_id}`,
                        value: city.fk_city_id,
                        label: `${city.city_name}, ${city.state_name}`,
                    });
                }
            });
            yield put({ type: user.EXISTING_CITIES_LIST_SUCCESS, payload: existingCities });
        } else {
            yield put({ type: user.EXISTING_CITIES_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.EXISTING_CITIES_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_EXISTING_CITY_LIST_LOADING, payload: false });
}

export function* updateServiceLocations(action) {
    const { form, servicePartnerId, formName, tabName, closedModal = true, updatedCities, userIds, status, roles } = action.payload;

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.SP.addCities, {}, { id: servicePartnerId }, {}, form);
        if (response.data.code === 201) {
            let message = 'Service Locations Updated Successfully';
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: message } });

            if (closedModal) {
                yield put({ type: modal.CLOSE_MODAL });
            }

            if (tabName === vendorsTab.path) {
                yield put({ type: customer.CUSTOMER_SERVICE_LOCATIONS_UPDATE, payload: { updatedCities, servicePartnerId } });
            } else {
                if (Array.isArray(userIds) && userIds.length > 0) {
                    const detailPayload = { userIds };
                    if (roles) detailPayload.roles = roles.split(',');
                    if (status) detailPayload.status = status;
                    const detailsResponse = yield call(microservices.user.getUserListDetails, {}, {}, {}, detailPayload);
                    if (detailsResponse.data.code === 200) {
                        yield put({ type: partners.PARTNERS_SERVICE_LOCATIONS_UPDATE, payload: { details: detailsResponse.data.data, userIds } });
                    }
                }
            }
        } else {
            let message = 'Service Locations Not Updated Successfully';
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: message } }),
                yield put({ type: user.UPDATE_SERVICE_LOCATIONS_FAILURE, payload: { message: 'Oops Something went wrong' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: user.UPDATE_SERVICE_LOCATIONS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updateUserTerritories(action) {
    const { data, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.user.updateUserTerritories, {}, {}, {}, data);
        if (response.data.code === 200) {
            let message = response.data.data.message ? response.data.data.message : `Territories Updated Successfully`;
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: message } }),
                yield put({ type: partners.REFRESH_PARTNER_FILTER_REQUEST }),
            ]);
        } else {
            let message = response.data.data.message ? response.data.data.message : 'Something went wrong';
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message } }),
                yield put({ type: user.UPDATE_USER_TERRITORIES_FAILURE, payload: { message: 'Oops Something went wrong' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: user.UPDATE_USER_TERRITORIES_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* removeUserTerritories(action) {
    const { data, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.user.removeUserTerritory, {}, {}, {}, data);
        if (response.data.code === 200) {
            let message = `Territories Updated Successfully`;
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: message } }),
                yield put({ type: partners.REFRESH_PARTNER_FILTER_REQUEST, }),
            ]);
        } else {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } }),
                yield put({ type: user.REMOVE_USER_TERRITORIES_FAILURE, payload: { message: 'Oops Something went wrong' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: user.REMOVE_USER_TERRITORIES_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getSPProfileDetails(action) {
    const { id } = action.payload;
    try {
        yield put({ type: loading.SET_SP_PROFILE_DETAILS_LOADING, payload: true });
        const response = yield call(api.SP.getProfile, {}, { id });
        if (response.data.code === 200) {
            yield put({ type: user.GET_SP_PROFILE_DETAILS_SUCCESS, payload: response.data.data });
        } else if (response.data.code === 400) {
            yield put({ type: user.GET_SP_PROFILE_DETAILS_FAILURE, payload: { message: response.data.message } });
        } else {
            yield put({ type: user.GET_SP_PROFILE_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.GET_SP_PROFILE_DETAILS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_SP_PROFILE_DETAILS_LOADING, payload: false });
}
export function* getFeProfileDetails(action) {
    const { id } = action.payload;
    try {
        yield put({ type: loading.SET_FE_PROFILE_DETAILS_LOADING, payload: true });
        const response = yield call(api.FE.getProfile, {}, { id });
        if (response.data.code === 200) {
            yield put({ type: user.GET_FE_PROFILE_DETAILS_SUCCESS, payload: response?.data?.data?.engineer_detail });
        } else {
            yield put({ type: user.GET_FE_PROFILE_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.GET_FE_PROFILE_DETAILS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_FE_PROFILE_DETAILS_LOADING, payload: false });
}

export function* inviteUser(action) {
    const loggedUser = yield select(getLoggedUser);
    const { form, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.invitations.sendInvite, {}, {}, {}, form);
        if (response.data.code === 201) {
            let toastsList = [];
            let errorsList = [];
            response.data.data.forEach((resp, index) => {
                if (resp.code === 201) {
                    return toastsList.push({
                        index: guid(),
                        status: 'success',
                        message: resp.data.message,
                    });
                } else {
                    if (typeof resp.message === 'string') {
                        return errorsList.push({
                            index: guid(),
                            status: 'danger',
                            message: resp.message,
                        });
                    } else if (typeof resp.message === 'object') {
                        return Object.keys(resp.message).map((err) => {
                            return errorsList.push({
                                index: guid(),
                                status: 'danger',
                                message: resp.message[err].join(' '),
                            });
                        });
                    }
                }
            });

            if (toastsList.length > 0) {
                yield put({ type: toast.SEND_TOAST, payload: { status: toastsList[0].status, message: toastsList[0].message } });
            }
            if (errorsList.length > 0) {
                yield put({ type: toast.SEND_TOAST, payload: { status: errorsList[0].status, message: errorsList[0].message } });
            }
            if (errorsList.length === 0) {
                yield put({ type: utils.SET_INITIAL_FORM, payload: { reinitialize: false, date: Date.now() } });
            }
            if (formName === 'AddNewFieldEngineers') {
                yield put({ type: modal.CLOSE_MODAL });
                yield put({ type: fe.FIELD_ENGINEERS_LIST_REQUEST, payload: { servicePartnerId: loggedUser?.service_partner_id } });
            }
        } else {
            yield put({ type: user.INVITE_USER_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.INVITE_USER_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* adminInviteSPUser(action) {
    const { form, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.invitations.adminSendInvite, { 'Content-Type': 'multipart/form-data' }, {}, {}, form);
        if (response.data.code === 201) {
            let toastsList = [];
            let errorsList = [];
            response.data.data.forEach((resp, index) => {
                if (resp.code === 201) {
                    return toastsList.push({
                        index: guid(),
                        status: 'success',
                        message: resp.data.message,
                    });
                } else {
                    if (typeof resp.message === 'string') {
                        return errorsList.push({
                            index: guid(),
                            status: 'danger',
                            message: resp.message,
                        });
                    } else if (typeof resp.message === 'object') {
                        return Object.keys(resp.message).map((err) => {
                            return errorsList.push({
                                index: guid(),
                                status: 'danger',
                                message: resp.message[err].join(' '),
                            });
                        });
                    }
                }
            });

            if (toastsList.length > 0) {
                yield put({ type: toast.SEND_TOAST, payload: { status: toastsList[0].status, message: toastsList[0].message } });
            }
            if (errorsList.length > 0) {
                yield put({ type: toast.SEND_TOAST, payload: { status: errorsList[0].status, message: errorsList[0].message } });
            }
            if (errorsList.length === 0) {
                yield put({ type: utils.SET_INITIAL_FORM, payload: { reinitialize: false, date: Date.now() } });
            }
        } else {
            yield put({ type: user.INVITE_USER_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.INVITE_USER_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* adminInviteCSPUser(action) {
    const { form, formName } = action.payload;

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.invitations.adminSendCSPInvite, {}, {}, {}, form);
        if (response.data.code === 201) {
            let toastsList = [];
            let errorsList = [];
            response.data.data.forEach((resp, index) => {
                if (resp.code === 201) {
                    return toastsList.push({
                        index: guid(),
                        status: 'success',
                        message: resp.data.message,
                    });
                } else {
                    if (typeof resp.message === 'string') {
                        return errorsList.push({
                            index: guid(),
                            status: 'danger',
                            message: resp.message,
                        });
                    } else if (typeof resp.message === 'object') {
                        return Object.keys(resp.message).map((err) => {
                            return errorsList.push({
                                index: guid(),
                                status: 'danger',
                                message: resp.message[err].join(' '),
                            });
                        });
                    }
                }
            });

            if (toastsList.length > 0) {
                yield put({ type: toast.SEND_TOAST, payload: { status: toastsList[0].status, message: toastsList[0].message } });
            }
            if (errorsList.length > 0) {
                yield put({ type: toast.SEND_TOAST, payload: { status: errorsList[0].status, message: errorsList[0].message } });
            }
            if (errorsList.length === 0) {
                yield put({ type: utils.SET_INITIAL_FORM, payload: { date: Date.now() } });
            }
        } else {
            yield put({ type: user.INVITE_USER_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.INVITE_USER_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updateTechSupportStatusOfUser(action) {
    const { userId, is_possible_tech_support, formName } = action.payload;
    try {
        const loggedUser = yield select(getLoggedUser);

        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.user.updateTechSupportStatusOfUser, {}, {}, {}, { userId, is_possible_tech_support });
        if (response.data.code === 200) {
            let status = 'success';
            let message = `Tech Support Status Changed`;
            const promises = [
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status, message } }),
            ];
            if (isCustomerGroup(loggedUser.role.id, loggedUser.type)) {
                promises.push(yield put({ type: customer.CUSTOMERS_ADDONS_LIST_REQUEST, payload: { customerId: loggedUser.customer_id } }))
            } else {
                promises.push(yield put({ type: partners.REFRESH_PARTNER_FILTER_REQUEST }))
            }
            yield all(promises);
        } else {
            let status = 'danger';
            let message = `Tech Support Status Change Failed - Something went wrong`;
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status, message } }),
                yield put({ type: user.UPDATE_TECH_SUPPORT_STATUS_OF_USER_FAILURE, payload: { message: 'Oops Something went wrong' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: user.UPDATE_TECH_SUPPORT_STATUS_OF_USER_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getTechEngineers() {
    yield put({ type: loading.LIST_TECH_ENGINEERS_LOADING, payload: true });
    try {
        const response = yield call(lambdas.user.getTechEngineers);
        if (response.data.code === 200) {
            yield put({ type: user.LIST_TECH_ENGINEERS_SUCCESS, payload: response.data.data.techSupport });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data?.message || 'Something went wrong' } });
            yield put({ type: user.LIST_TECH_ENGINEERS_FAILURE });
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error.message || 'Something went wrong' } });
        yield put({ type: user.LIST_TECH_ENGINEERS_FAILURE, payload: error });
    }
    yield put({ type: loading.LIST_TECH_ENGINEERS_LOADING, payload: false });
}

export function* getUserDetails(action) {
    const { userId } = action.payload;
    const queryparams = {
        giveTeams: true,
    };
    try {
        yield put({ type: loading.GET_USER_DETAILS_LOADING, payload: true });
        const response = yield call(lambdas.user.getUserDetails, {}, { userId }, queryparams, {});
        if (response.data.code === 200) {
            const userDetails = response.data.data;
            yield put({ type: user.GET_USER_DETAILS_SUCCESS, payload: userDetails });
        } else {
            yield put({ type: user.GET_USER_DETAILS_SUCCESS, payload: null });
            yield put({ type: user.GET_USER_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.GET_USER_DETAILS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_USER_DETAILS_LOADING, payload: false });
}

export function* getCourierVendors(action) {
    yield put({ type: loading.GET_COURIER_VENDORS_LOADING, payload: true });
    try {
        const response = yield call(api.courier.getVendors, {});
        if (response.data.code === 200) {
            let courierVendorsList = [];
            response.data.data.courier_vendor_list.map((vendor) => {
                return courierVendorsList.push({
                    key: vendor.courier_vendor_id,
                    value: vendor.courier_vendor_id,
                    label: vendor.courier_company_name,
                });
            });
            yield put({ type: user.COURIER_VENDORS_LIST_SUCCESS, payload: courierVendorsList });
        } else {
            yield put({ type: user.COURIER_VENDORS_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.COURIER_VENDORS_LIST_FAILURE, payload: error });
    }
    yield put({ type: loading.GET_COURIER_VENDORS_LOADING, payload: false });
}

export function* reInviteFe(action) {
    const { feId, formName, toastMsg } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.invitations.inviteFE, {}, { feId });
        if (response.data.code === 200) {
            const message = toastMsg ? toastMsg : 'Field Engineer Re-Invited successfully';
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } });
        } else {
            yield put({ type: user.REINVITE_FE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.REINVITE_FE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* newResourceFolder(action) {
    const { resources, parentPath, toastMsg, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const parentFolderIdArray = parentPath.split('/');
        let parentFolderId = parentFolderIdArray[parentFolderIdArray.length - 1];
        if (!parentFolderId) parentFolderId = '';
        const requestBody = {
            resources: resources.map((r) => ({
                ...r,
                parentFolderId,
            })),
        };
        const response = yield call(microservices.user.newResource, {}, {}, {}, requestBody);

        if (response.data.code === 201) {
            const message = toastMsg ? toastMsg : 'Folder Created Successfully';
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } }),
                yield put({ type: user.GET_RESOURCE_REQUEST, payload: { parentPath } }),
            ]);
        }
        let toastData = [];
        if (response.data.data.errors.length > 0) {
            response.data.data.errors.forEach(({ message }) => {
                toastData.push({ message, status: 'danger' });
            });
            const { message, status } = toastData[0];
            yield put({ type: toast.SEND_TOAST, payload: { status, message } });
        }

        if (response.data.code !== 201) {
            yield all([yield put({ type: user.NEW_RESOURCE_ADD_FAILURE, payload: { message: 'Oops Something went wrong' } })]);
        }
    } catch (error) {
        yield put({ type: user.NEW_RESOURCE_ADD_FAILURE, payload: { message: error } });
        yield put({ type: modal.CLOSE_MODAL });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* newResourceFile(action) {
    let { parentPath, fileData, reason = 'RESOURCE', formName } = action.payload;

    const parentFolderIdArray = parentPath.split('/');
    let parentFolderId = parentFolderIdArray[parentFolderIdArray.length - 1];
    if (!parentFolderId) parentFolderId = '';

    const files = new Array(...fileData.map((item) => item.file));
    const filesInRequestBody = fileData.map(({ file }) => ({
        fileName: file.name,
        mimeType: file.type,
    }));

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.user.newResourceFile, {}, {}, {}, { files: filesInRequestBody, parentFolderId, reason });
        if (response.data.code === 200) {
            yield* response.data.data.map(({ signedUrl, fileName }) => {
                const file = files.find((f) => f.name === fileName);
                return call(uploadFileToS3WithSignedUrl, signedUrl, file);
            });
            const requestBody = {
                resources: response.data.data.map(({ fileUrl, mimeType, fileName }) => {
                    return {
                        resourceName: fileName,
                        parentFolderId: parentFolderId,
                        resourceType: 'file',
                        fileInfo: { mimeType, fileUrl },
                    };
                }),
            };
            const saveResponse = yield call(microservices.user.newResource, {}, {}, {}, requestBody);
            if (saveResponse.data.code === 201 && saveResponse.data.data.createdResources.length > 0) {
                let message = `${saveResponse.data.data.createdResources.length} File Uploaded Successfully`;
                yield all([
                    yield put({ type: modal.CLOSE_MODAL }),
                    yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } }),
                    yield put({ type: user.GET_RESOURCE_REQUEST, payload: { parentPath } }),
                ]);
            }
            let toastData = [];
            if (saveResponse.data.data.errors.length > 0) {
                saveResponse.data.data.errors.forEach(({ message }) => {
                    toastData.push({ message, status: 'danger' });
                });
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Oops Something went wrong' } });
                yield put({ type: modal.CLOSE_MODAL });
            }

            if (saveResponse.data.code !== 201) {
                yield put({ type: user.NEW_RESOURCE_FILE_ADD_FAILURE, payload: { message: 'Could not upload files' } });
                yield put({ type: modal.CLOSE_MODAL });
            }
        } else {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something Went wrong' } }),
                yield put({ type: user.NEW_RESOURCE_FILE_ADD_FAILURE, payload: { message: 'Could not upload files' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: user.NEW_RESOURCE_FILE_ADD_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getResource(action) {
    const { parentPath } = action.payload;
    try {
        yield put({ type: loading.GET_RESOURCE_LOADING, payload: true });
        const response = yield call(microservices.user.getResource, {}, {}, { parentPath }, {});
        if (response.data.code === 200) {
            yield put({ type: user.GET_RESOURCE_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: user.GET_RESOURCE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.GET_RESOURCE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_RESOURCE_LOADING, payload: false });
}

export function* deleteUserResource(action) {
    const { resourceId, formName, toastMsg, parentPath } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(microservices.user.deleteResource, {}, {}, {}, { resourceId });
        if (response.data.code === 200) {
            let message = toastMsg ? toastMsg : 'Resource Deleted Successfully';
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } }),
                yield put({ type: user.GET_RESOURCE_REQUEST, payload: { parentPath } }),
            ]);
        } else {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } }),
                yield put({ type: user.DELETE_RESOURCE_FAILURE, payload: { message: 'Oops Something went wrong' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: user.DELETE_RESOURCE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* createRootFolder(action) {
    const { userId, navigate } = action.payload;
    try {
        const response = yield call(microservices.user.createRootFolder, {}, {}, {}, { userId });
        if (response.data.code === 200) {
            yield put({ type: user.CREATE_ROOT_FOLDER_SUCCESS, payload: { _id: response.data.resource._id } });
            navigate(`/documents?path=/${response.data.resource._id}`);
        } else {
            yield put({ type: user.CREATE_ROOT_FOLDER_FAILURE, payload: { message: 'Something went wrong!' } });
        }
    } catch (error) {
        yield put({ type: user.CREATE_ROOT_FOLDER_FAILURE, payload: { message: error } });
    }
}

export function* addEndUser(action) {
    const { end_user_name, formName, customerId } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.user.createEndUser, {}, {}, {}, { end_user_name, customerId });
        if (response.data.code === 201) {
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: user.ADD_END_USER_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: user.ADD_END_USER_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: modal.CLOSE_MODAL });
        yield put({ type: user.ADD_END_USER_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getUserInfo(action) {
    const { userId } = action.payload;
    try {
        yield put({ type: loading.SET_SP_PROFILE_DETAILS_LOADING, payload: true });
        const response = yield call(api.auth.getUser, {}, { userId }, {}, {});
        if (response.data.code === 200) {
            yield put({ type: user.GET_USER_INFO_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: user.GET_USER_INFO_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.GET_USER_INFO_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_SP_PROFILE_DETAILS_LOADING, payload: false });
}

export function* getUsersRatecards(action) {
    const { customerId = 0 } = action.payload || {};
    let queryparams = {};
    if (!['', null, undefined].includes(customerId)) queryparams.customerId = customerId;
    try {
        yield put({ type: loading.GET_USERS_RATECARD_LOADING, payload: true });
        const response = yield call(lambdas.user.getUsersRatecard, {}, {}, queryparams);
        if (response.data.code === 200) {
            yield put({ type: user.GET_USERS_RATECARD_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: user.GET_USERS_RATECARD_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.GET_USERS_RATECARD_FAILURE, payload: error });
    }
    yield put({ type: loading.GET_USERS_RATECARD_LOADING, payload: false });
}

export function* getBanksList(action) {
    try {
        const response = yield call(api.banks.getAll, {});
        if (response.data.code === 200) {
            yield put({ type: user.BANKS_LIST_SUCCESS, payload: response.data.data.bank_list });
        } else {
            yield put({ type: user.BANKS_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.BANKS_LIST_FAILURE, payload: error });
    }
}

export function* updateUserBankDetailsAndPay(action) {
    const { form, id, formName, callPay = false, isRefresh, payPoData, callAfterBankSubmit = () => { }, type, source, ticketId, withdrawAmount, userId, remark } = action.payload;

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.user.saveBankDetails, {}, { id }, {}, form);
        if (response.data.code === 201) {
            if (source === 'add_upi_id_and_pay') {
                yield put({
                    type: wallet.WITHDRAW_WALLET_AMOUNT_REQUEST, payload: {
                        refresh: false,
                        data: {
                            "type": "WITHDRAW",
                            "paymentMode": "UPI",
                            "recepient": userId ? userId : id,
                            "ticketId": ticketId,
                            "withdrawAmount": withdrawAmount,
                            remark
                        },
                        formName,
                        ticketId
                    }
                });
            } else {
                let actionArray = [
                    yield put({ type: user.UPDATE_USERS_BANK_DETAILS_SUCCESS, payload: response.data }),
                    yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Bank account updated successfully.' } }),
                ];
                if (callPay) {
                    actionArray.push(yield put({ type: wallet.PAY_PO_REQUEST, payload: { isRefresh, data: payPoData, formName } }));
                }
                if (isRefresh) {
                    actionArray.push(yield put({ type: auth.GET_USERS_BANK_DETAILS_REQUEST, payload: { id, type } }));
                    actionArray.push(yield put({ type: auth.GET_USERS_BANK_DETAILS_BY_ID_REQUEST, payload: { id, type, source: ticketId ? 'ticket_bank_transfer' : '' } }));
                }
                yield all(actionArray);
            }
            callAfterBankSubmit();
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.UPDATE_USERS_BANK_DETAILS_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* pancardValidation(action) {
    const { pan_number } = action.payload;
    let data = {
        action: 'VERIFY_PANCARD',
        data: {
            pan_number: `${pan_number}`,
        },
    };
    try {
        yield put({ type: loading.IS_PANCARD_VERIFICATION_LOADING, payload: true });
        const response = yield call(microservices.user.validateTroughSurePass, {}, {}, {}, data);
        if (response.data.code === 200) {
            yield all([
                yield put({ type: user.PANCARD_VALIDATION_SUCCESS, payload: { data: response.data.data } }),
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'PAN Verification Success' } }),
            ]);
        } else {
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'PAN Verification Failed' } }),
                yield put({ type: user.PANCARD_VALIDATION_FAILURE, payload: { message: 'Oops Something went wrong' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: user.PANCARD_VALIDATION_FAILURE, payload: error });
    }
    yield put({ type: loading.IS_PANCARD_VERIFICATION_LOADING, payload: false });
}

export function* bussinessPancardValidation(action) {
    const { pan_number } = action.payload;
    let data = {
        action: 'VERIFY_PANCARD',
        data: {
            pan_number: `${pan_number}`,
        },
    };
    try {
        yield put({ type: loading.IS_BUSSINESS_PANCARD_VERIFICATION_LOADING, payload: true });
        const response = yield call(microservices.user.validateTroughSurePass, {}, {}, {}, data);
        if (response.data.code === 200) {
            yield put({ type: user.BUSSINESS_PANCARD_VALIDATION_SUCCESS, payload: { data: response.data.data } });
        } else {
            yield put({ type: user.BUSSINESS_PANCARD_VALIDATION_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.BUSSINESS_PANCARD_VALIDATION_FAILURE, payload: error });
    }
    yield put({ type: loading.IS_BUSSINESS_PANCARD_VERIFICATION_LOADING, payload: false });
}

export function* gstValidation(action) {
    const { gst_number } = action.payload;
    let data = {
        action: 'GST',
        data: {
            gstin_number: `${gst_number}`,
        },
    };
    yield put({ type: loading.IS_GST_VERIFICATION_LOADING, payload: true });
    try {
        const response = yield call(microservices.user.validateTroughSurePass, {}, {}, {}, data);
        if (response.data.code === 200) {
            yield put({ type: user.GST_VALIDATION_SUCCESS, payload: { data: response.data.data, gst_number } });
        } else {
            yield put({ type: user.GST_VALIDATION_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.GST_VALIDATION_FAILURE, payload: error });
    }
    yield put({ type: loading.IS_GST_VERIFICATION_LOADING, payload: false });
}

export function* adhaarValidation(action) {
    const { aadhaar_number, already_verified } = action.payload;
    let data = {
        action: 'GENERATE_AADHAAR_OTP',
        data: {
            aadhaar_number: aadhaar_number,
        },
    };
    let queryparams = {};
    if (already_verified !== undefined) queryparams.already_verified = already_verified;
    try {
        yield put({ type: loading.AADHAR_VALIDAION_LOADING, payload: true });
        const response = yield call(microservices.user.validateTroughSurePass, {}, {}, queryparams, data);
        if (response.data.code === 200) {
            localStorage.setItem('client_id', response.data.data.client_id);
            const isPersonAdult = isPerson18YearOld(response.data.data.dob);
            yield put({ type: user.USER_AADHAAR_VALIDATE_SUCCESS, payload: { ...response.data, isPersonAdult } });
        } else {
            yield put({
                type: toast.SEND_TOAST,
                payload: { status: 'danger', message: `OTP Verification Failed - Something went wrong :: INVALID OTP` },
            });
            yield put({ type: user.USER_AADHAAR_VALIDATE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.USER_AADHAAR_VALIDATE_FAILURE, payload: error });
    }
    yield put({ type: loading.AADHAR_VALIDAION_LOADING, payload: false });
}

export function* submitAdhaarOtp(action) {
    const client_id = localStorage.getItem('client_id');
    const { otp, formName, aadhaar_number, closeMoadal = true } = action.payload;
    let data = {
        action: 'VERIFY_AADHAAR_OTP',
        data: {
            client_id: `${client_id}`,
            otp: otp,
        },
    };

    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });

    try {
        const response = yield call(microservices.user.validateTroughSurePass, {}, {}, {}, data);
        const isPersonAdult = isPerson18YearOld(response.data.data.dob)

        if (response.data.code === 200) {
            const a = [yield put({ type: user.SUBMIT_AADHAAR_OTP_SUCCESS, payload: { data: { ...response.data.data, isPersonAdult }, aadhaar_number } }),
            yield put({
                type: toast.SEND_TOAST,
                payload: { status: 'success', message: `OTP Verification Success` },
            }),]
            if (!isPersonAdult) {
                a.push(
                    yield put({
                        type: toast.SEND_TOAST,
                        payload: { status: 'danger', message: 'You are under 18 ' },
                    }),
                )
            }
            yield all(a);
            if (closeMoadal) yield put({ type: modal.CLOSE_MODAL });
        } else {
            yield all([
                yield put({
                    type: toast.SEND_TOAST,
                    payload: {
                        status: 'danger',
                        message: response?.data?.data?.message ? response.data.data.message : `OTP Verification Failed - Something went wrong :: INVALID OTP`,
                    },
                }),
                yield put({ type: user.SUBMIT_AADHAAR_OTP_FAILURE, payload: { message: 'Oops Something went wrong' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: user.SUBMIT_AADHAAR_OTP_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* bankVerification(action) {
    const { account_number, ifsc } = action.payload;
    let data = {
        action: 'VERIFY_BANK_ACCOUNT',
        data: {
            account_number: `${account_number}`,
            ifsc: `${ifsc}`,
        },
    };
    try {
        const response = yield call(microservices.user.validateTroughSurePass, {}, {}, {}, data);
        if (response.data.code === 200) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: `Bank Verification Success` } });
            yield put({ type: user.BANK_VERIFICATION_SUCCESS, payload: true });
        } else {
            yield put({
                type: toast.SEND_TOAST,
                payload: {
                    status: 'danger',
                    message: response?.data?.data?.message ? response.data.data.message : `Bank Verification Failed - Something went wrong`,
                },
            });
            yield put({ type: user.BANK_VERIFICATION_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.BANK_VERIFICATION_FAILURE, payload: error });
    }
}

export function* initailizeEsign(action) {
    let data = {
        action: 'ESIGN',
        data: {},
    };
    try {
        const response = yield call(microservices.user.validateTroughSurePass, {}, {}, {}, data);
        if (response.data.code === 200) {
            yield put({ type: user.ESIGN_TOKEN_SUCCESS });
            localStorage.setItem('esign_client_id', response.data.data.client_id);
            localStorage.setItem('esign_token', response.data.data.token);
        } else {
            yield put({
                type: toast.SEND_TOAST,
                payload: {
                    status: 'danger',
                    message: response?.data?.data?.message ? response.data.data.message : `ESIGN ERROR- Something went wrong`,
                },
            });
            yield put({ type: user.ESIGN_TOKEN_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.ESIGN_TOKEN_FAILURE, payload: error });
    }
}

export function* getTechSupportUsers() {
    try {
        yield put({ type: loading.GET_TECH_SUPPORT_USERS_LOADING, payload: true });
        const response = yield call(lambdas.user.getTechSupportUsers, {});
        if (response.data.code === 200) {
            let dropdownList = response.data.data.techSupport.map((user) => {
                let status = 'Out of Office';
                let activeIcon = { icon_name: 'lens', color: 'scogoclosed' };
                if (user.working_status === 'ACTIVE') {
                    status = 'Active';
                    activeIcon.color = 'scogoToastSuccess';
                }
                let icons = { icon_name: '', color: '' };
                icons.leftSide = [activeIcon];
                return {
                    key: user.id,
                    value: user.id,
                    label: `${user.first_name}  ${user.last_name} (${status})`,
                    user: user,
                    icons,
                };
            });

            yield put({ type: user.GET_TECH_SUPPORT_USERS_DROPDOWN_SUCCESS, payload: dropdownList });
        } else {
            yield put({ type: user.GET_TECH_SUPPORT_USERS_DROPDOWN_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.GET_TECH_SUPPORT_USERS_DROPDOWN_FAILURE, payload: error });
    }
    yield put({ type: loading.GET_TECH_SUPPORT_USERS_LOADING, payload: false });
}

export function* generateOTP(action) {
    yield put({ type: loading.GENRATE_OTP_LOADING, payload: true });
    const { mobile } = action.payload;
    try {
        const response = yield call(api.user.generateOTP, {}, {}, {}, { mobile });
        if (response.data.code === 200) {
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: `OTP Generated Successfully` } }),
                yield put({ type: user.GENERATE_OTP_SUCCESS, payload: true }),
            ]);
        } else {
            yield put({ type: user.GENERATE_OTP_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.GENERATE_OTP_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GENRATE_OTP_LOADING, payload: false });
}

export function* verifyOTP(action) {
    yield put({ type: loading.VERIFY_OTP_LOADING, payload: true });
    const { mobile, temp_otp } = action.payload;
    try {
        const response = yield call(api.user.verifyOTP, {}, {}, {}, { mobile, temp_otp });
        if (response.data.code === 201) {
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: `Mobile Number Updated Successfully` } }),
                yield put({ type: user.VERIFY_OTP_SUCCESS, payload: { mobile, temp_otp } }),
            ]);
        } else {
            yield put({ type: user.VERIFY_OTP_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.VERIFY_OTP_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.VERIFY_OTP_LOADING, payload: false });
}

export function* updateUserProfileBankDetails(action) {
    const { bankFormData, vendorId, formName, bankVerificationPayload } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        let bankVerification = { type: user.BANK_VERIFICATION_SUCCESS };
        yield put({ type: user.BANK_VERIFICATION_REQUEST, payload: bankVerificationPayload });
        bankVerification = yield take([user.BANK_VERIFICATION_SUCCESS, user.BANK_VERIFICATION_FAILURE]);
        if (bankVerification.type === user.BANK_VERIFICATION_SUCCESS) {
            const response = yield call(api.user.saveBankDetails, {}, { id: vendorId }, {}, bankFormData);
            if (response.data.code === 201) {
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Bank Details Updated Successfully' } });
                yield put({ type: auth.GET_USERS_BANK_DETAILS_REQUEST, payload: { id: vendorId } });
                yield put({ type: modal.CLOSE_MODAL });
            }
        } else {
            yield put({ type: user.UPDATE_USER_PROFILE_BANK_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.UPDATE_USER_PROFILE_BANK_DETAILS_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* addGst(action) {
    yield put({ type: loading.ADD_GST_LOADING, payload: true });
    const { gstlist, userId } = action.payload;
    try {
        const response = yield call(microservices.user.addGst, {}, {}, {}, { userId, gstlist });
        if (response.data.code === 200) {
            yield put({ type: user.ADD_GST_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: user.ADD_GST_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.ADD_GST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.ADD_GST_LOADING, payload: false });
}

export function* getGstList(action) {
    yield put({ type: loading.GET_GST_LIST_LOADING, payload: true });
    const { userId } = action.payload;
    try {
        const response = yield call(microservices.user.getGstList, {}, {}, { userId });
        if (response.data.code === 200) {
            yield put({ type: user.GET_GST_LIST_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: user.GET_GST_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.GET_GST_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_GST_LIST_LOADING, payload: false });
}

export function* getGstById(action) {
    yield put({ type: loading.GET_GST_LIST_BY_ID_LOADING, payload: true });
    const { gstId } = action.payload;
    try {
        const response = yield call(microservices.user.getGstById, {}, { gstId }, {}, {});
        if (response.data.code === 201) {
            yield put({ type: user.GET_GST_BY_ID_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: user.GET_GST_BY_ID_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.GET_GST_BY_ID_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_GST_LIST_BY_ID_LOADING, payload: false });
}

export function* updateGstById(action) {
    const { gstId, updatePayload } = action.payload;
    yield put({ type: loading.UPDATE_GST_BY_ID_LOADING, payload: { [gstId]: true } });
    try {
        const response = yield call(microservices.user.updateGstById, {}, { gstId }, {}, updatePayload);
        if (response.data.code === 200) {
            yield put({ type: user.UPDATE_GST_SUCCESS, payload: { gstId, updatePayload } });
        } else {
            yield put({ type: user.UPDATE_GST_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.UPDATE_GST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.UPDATE_GST_BY_ID_LOADING, payload: { [gstId]: false } });
}

export function* deleteGstById(action) {
    const { gstId, formName } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(microservices.user.deleteGstById, {}, { gstId }, {}, {});
        if (response.data.code === 200) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
            yield put({ type: user.DELETE_GST_SUCCESS, payload: { gstId } });
        } else {
            yield put({ type: user.DELETE_GST_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.DELETE_GST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}


export function* uploadAddressProofBackAndFront(action) {
    const { addressProofType, addressProofNumber, proofImage, formName, fileUrlBack, userId, refreshUserTable, tabName, customerId } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const responseBack = yield call(lambdas.user.uploadProof, {}, {}, {}, {
            files: [{
                fileName: proofImage.name,
                mimeType: proofImage.type,
            }], addressProofType, reason: 'ADDRESS_PROOF', isBack: true, userId
        });
        const responseFront = yield call(lambdas.user.uploadProof, {}, {}, {}, {
            files: [{
                fileName: fileUrlBack.name,
                mimeType: fileUrlBack.type,
            }], addressProofType, reason: 'ADDRESS_PROOF', userId
        });
        if (responseBack.status === 200 && responseFront.status === 200) {
            yield call(uploadFileToS3WithSignedUrl, responseFront.data.signedUrl, proofImage);
            yield call(uploadFileToS3WithSignedUrl, responseBack.data.signedUrl, fileUrlBack);
            let saveDataPayload = { fileUrl: responseFront.data.fileUrl, addressProofType, addressProofNumber, fileUrlBack: responseBack.data.fileUrl, };
            if (userId) saveDataPayload.userId = userId;
            const saveData = yield call(lambdas.user.updateProofAddress, {}, {}, {}, saveDataPayload);
            if (saveData.data.code === 200) {
                const { address_proof_number, address_proof_type, address_proof_url, address_proof_url_back } = saveData.data.data;
                yield all([
                    yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Address Proof Updated Successfully' } }),
                    yield put({ type: user.UPLOAD_USER_ID_PROOF_FRONT_BACK_SUCCESS, payload: { address_proof_number, address_proof_type, address_proof_url, address_proof_url_back } }),
                    yield put({ type: modal.CLOSE_MODAL })
                ]);
            }
            if (refreshUserTable) {
                if (tabName === 'customer_vendors') {
                    yield put({ type: customer.GET_CUSTOMER_VENDORS_LIST_REQUEST, payload: { customer_id: customerId } });
                } else {
                    yield put({ type: partners.REFRESH_PARTNER_FILTER_REQUEST });
                }
            }
        } else {
            yield put({ type: user.UPLOAD_USER_ID_PROOF_FRONT_BACK_FAILURE, payload: 'Something Went Wrong!!!' });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Address Proof Update Failed' } });
        }
    } catch (error) {
        yield put({ type: user.UPLOAD_USER_ID_PROOF_FRONT_BACK_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* uploadAddressProofBack(action) {
    const { addressProofType, addressProofNumber, fileUrlFront, formName, backFile } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const responseBack = yield call(lambdas.user.uploadProof, {}, {}, {}, {
            files: [{
                fileName: backFile.name,
                mimeType: backFile.type,
            }], addressProofType, reason: 'ADDRESS_PROOF', isBack: true
        });
        if (responseBack.status === 200) {
            yield call(uploadFileToS3WithSignedUrl, responseBack.data.signedUrl, backFile);
            let saveDataPayload = { fileUrl: fileUrlFront, addressProofType, addressProofNumber, fileUrlBack: responseBack.data.fileUrl, };
            const saveData = yield call(lambdas.user.updateProofAddress, {}, {}, {}, saveDataPayload);
            if (saveData.data.code === 200) {
                const { address_proof_number, address_proof_type, address_proof_url, address_proof_url_back } = saveData.data.data;
                yield all([
                    yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Address Proof Updated Successfully' } }),
                    yield put({ type: user.UPLOAD_USER_ID_PROOF_FRONT_BACK_SUCCESS, payload: { address_proof_number, address_proof_type, address_proof_url, address_proof_url_back } }),
                    yield put({ type: modal.CLOSE_MODAL })
                ]);
            }
        } else {
            yield put({ type: user.UPLOAD_USER_ID_PROOF_FRONT_BACK_FAILURE, payload: 'Something Went Wrong!!!' });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Address Proof Update Failed' } });
        }
    } catch (error) {
        yield put({ type: user.UPLOAD_USER_ID_PROOF_FRONT_BACK_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getUserProfileDetails(action) {
    const { userId, from } = action.payload;
    yield put({ type: loading.USER_DETAILS_LOADING, payload: true });
    const queryParams = {}
    if (!['', undefined, null].includes(from)) queryParams.from = from
    try {
        const response = yield call(lambdas.user.userProfileDetails, {}, { userId }, queryParams, {});

        if (response.data.code === 200) {
            yield put({ type: user.USER_PROFILE_DETAILS_SUCCESS, payload: { userDetails: response.data.data, userId } });
        } else {
            yield put({ type: user.USER_PROFILE_DETAILS_FAILUREE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.USER_PROFILE_DETAILS_FAILUREE, payload: { message: error } });
    }
    yield put({ type: loading.USER_DETAILS_LOADING, payload: false });
}


export function* verifyUserAddress(action) {
    const { userId, toAccept, formName } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(lambdas.user.verifyAddress, {}, {}, {}, { userId, toAccept });
        if (response.data.code === 200) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
            yield put({ type: partners.REFRESH_PARTNER_FILTER_REQUEST });
            yield put({ type: modal.CLOSE_MODAL })
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message ? response.data.data.message : 'Something went wrong' } });
            yield put({ type: user.VERIFY_USER_ADDRESS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.VERIFY_USER_ADDRESS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* bulkInviteUseFileUpload(action) {

    const { attachment, formName, type, customerId, roleId, sendNotification } = action.payload;

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });

        const signedUrl = yield call(lambdas.user.bulkInveteFileUpload, {}, {}, { type, customerId, roleId, sendNotification }, {}, {});

        if (signedUrl.status === 200) {
            yield call(uploadFileToS3WithSignedUrl, signedUrl.data.data.url, attachment);
            yield put({ type: modal.CLOSE_MODAL })
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Bulk Invite Successfully' } })
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: signedUrl.data.data.message ? signedUrl.data.data.message : 'Something Went wrong' } })
        }
    } catch (error) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* enableRoom(action) {
    let { userId, ticketId, conversationId, onSuccess = () => { }, buttonName } = action.payload;
    try {
        if (buttonName) yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName: buttonName, isLoading: true } });
        yield put({ type: loading.IS_ENABLE_VC_USER_LOADING, payload: true });
        if (!ticketId) {
            const response = yield call(lambdas.conversations.getConversationInfo, {}, { _id: conversationId }, { query: '_id' });
            if (response.data.code === 200) {
                conversationId = response.data.data._id;
            }
            else if (response.data.code === 404) {
                const otherPersonUserId = buttonName?.split('-')?.pop();
                let singleChatPayload = { chats: [{ type: 'single', users: [{ userId }] }] };
                const createdConversationResponse = yield call(lambdas.conversations.createChat, {}, {}, {}, singleChatPayload);
                if (createdConversationResponse.data.code === 201) {
                    conversationId = createdConversationResponse.data.data.createdChats[0]._id;
                }
            }
        }
        const response = yield call(microservices.user.enableUserRoom, {}, { userId }, {}, { ticketId, conversationId });
        yield put({ type: loading.IS_ENABLE_VC_USER_LOADING, payload: false });
        if (response.data.code === 200) {
            yield put({ type: user.DISABLE_USER_ROOM_SUCCESS, payload: false });
            yield put({ type: user.ENABLE_USER_ROOM_SUCCESS, payload: response.data.data.room_id });
            onSuccess({ room_id: response.data.data.room_id, conversationId: conversationId });
        } else {
            yield put({ type: user.ENABLE_USER_ROOM_FAILURE, payload: { status: 'danger', message: 'Something went wrong!!' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Could not start call, try again' } });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: user.ENABLE_USER_ROOM_FAILURE, payload: { status: 'danger', message: 'Something went wrong!!' } });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Could not start call, try again' } });
    }
    if (buttonName) yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName: buttonName, isLoading: false } });
}

export function* disableRoom(action) {
    let { userId, ticketId, conversationId, roomId, onSuccess = () => { } } = action.payload;
    try {
        yield put({ type: loading.IS_DISABLE_VC_USER_LOADING, payload: true });
        const response = yield call(microservices.user.disableUserRoom, {}, { userId }, {}, { ticketId, conversationId, roomId });
        if (response.data.code === 200) {
            yield put({ type: user.DISABLE_USER_ROOM_SUCCESS, payload: response.data.data });
            onSuccess();
        } else {
            yield put({ type: user.DISABLE_USER_ROOM_FAILURE, payload: { status: 'danger', message: 'Something went wrong!!' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Could not end call, try again' } });
        }
    } catch (error) {
        yield put({ type: user.DISABLE_USER_ROOM_FAILURE, payload: { status: 'danger', message: 'Something went wrong!!' } });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Could not end call, try again' } });
    }
    yield put({ type: loading.IS_DISABLE_VC_USER_LOADING, payload: false });
}

export function* getUserCallStatus(action) {
    const { userId, roomId, status } = action.payload;
    try {
        const response = yield call(microservices.user.userCallStatus, {}, { userId }, {}, { userId, roomId, status });
        if (response.data.code === 200) {
            yield put({ type: user.GET_USER_CALL_STATUS_SUCCESS, payload: response.data?.data?.call_status });
        }
        else {
            yield put({ type: user.GET_USER_CALL_STATUS_FAILURE, payload: { status: 'danger', message: 'Something went wrong!!' } });
        }
    } catch (error) {
        yield put({ type: user.GET_USER_CALL_STATUS_FAILURE, payload: { status: 'danger', message: 'Something went wrong!!' } });
    }
}

export function* getEndUsers(action) {
    const { customerId, limit = 10, page = 1, query, source } = action.payload;
    const offset = (page - 1) * limit;
    const queryParams = { customerId, limit, offset, source };
    if (query) queryParams.query = query;
    try {
        if (page > 1) {
            yield put({ type: loading.SET_NEXT_ENDUSERS_LIST_REQUEST, payload: true });
        } else {
            yield put({ type: loading.SET_ENDUSERS_LIST_REQUEST, payload: true });
        }
        const response = yield call(lambdas.user.getEndUser, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: user.GET_ENDUSERS_LIST_SUCCESS, payload: { data: response.data.data, page } });
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    if (page > 1) {
        yield put({ type: loading.SET_NEXT_ENDUSERS_LIST_REQUEST, payload: false });
    } else {
        yield put({ type: loading.SET_ENDUSERS_LIST_REQUEST, payload: false });
    }
}

export function* checkAndUpdateKycStatus(action) {
    const { pancard_number, adhaar_number, refreshUserTable = false, tabName, userId, callDetails } = action.payload;
    try {
        yield put({ type: loading.IS_CHECK_AND_UPDATE_KYC_STATUS_LOADING, payload: { loading: true, userId } });
        const { loggedUser } = yield select((state) => state.auth);
        const response = yield call(microservices.user.validateTroughSurePass, {}, {}, {}, {
            "action": "CHECK_PANCARD_AADHAAR_LINK_STATUS",
            "data": {
                pan_number: pancard_number, aadhaar_number: adhaar_number, userId
            }
        });
        console.log('response:', response)
        if (refreshUserTable) {
            if (tabName === 'customer_vendors') {
                yield put({ type: customer.GET_CUSTOMER_VENDORS_LIST_REQUEST, payload: { customer_id: loggedUser.customer_id } });
            } else {
                yield put({ type: partners.REFRESH_PARTNER_FILTER_REQUEST });
            }
        }
        if (callDetails) {
            yield put({
                type: user.GET_SP_PROFILE_DETAILS_REQUEST,
                payload: { id: userId }
            })
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.IS_CHECK_AND_UPDATE_KYC_STATUS_LOADING, payload: { loading: false, userId } });

}

export default function* userSaga() {
    yield takeEvery(user.UPDATE_USERS_BANK_DETAILS_REQUEST, updateUserBankDetailsAndPay);
    yield takeEvery(user.SERVICETYPE_LIST_REQUEST, getServiceTypesList);
    yield takeEvery(user.USECASES_LIST_REQUEST, getAllUsecases);
    yield takeEvery(user.COURIER_VENDORS_LIST_REQUEST, getCourierVendors);
    yield takeEvery(user.GET_NOTIFICATION_REQUEST, getNotification);
    yield takeEvery(user.GET_NEXT_NOTIFICATION_REQUEST, getNextNotification);
    yield takeEvery(user.READ_NOTIFICATION_REQUEST, readNotification);
    yield takeLatest(user.UPDATE_USER_STATUS_REQUEST, updateUserStatus);
    yield takeLatest(user.UPDATE_PARTNER_PROFILE_REQUEST, updatePartnersProfile);
    yield takeLatest(user.PROMOTE_USER_REQUEST, promoteUser);
    yield takeLatest(user.DEMOTE_USER_REQUEST, demoteUser);
    yield takeEvery(user.STATES_LIST_REQUEST, getAllStates);
    yield takeEvery(user.EXISTING_CITIES_LIST_REQUEST, getAllExistingCities);
    yield takeLatest(user.UPDATE_SERVICE_LOCATIONS_REQUEST, updateServiceLocations);
    yield takeLatest(user.UPDATE_USER_TERRITORIES_REQUEST, updateUserTerritories);
    yield takeEvery(user.GET_SP_PROFILE_DETAILS_REQUEST, getSPProfileDetails);
    yield takeLatest(user.INVITE_USER_REQUEST, inviteUser);
    yield takeLatest(user.ADMIN_INVITE_SP_USER_REQUEST, adminInviteSPUser);
    yield takeLatest(user.ADMIN_INVITE_CSP_USER_REQUEST, adminInviteCSPUser);
    yield takeLatest(user.UPDATE_TECH_SUPPORT_STATUS_OF_USER_REQUEST, updateTechSupportStatusOfUser);
    yield takeLatest(user.GET_USER_DETAILS_REQUEST, getUserDetails);
    yield takeLatest(user.REINVITE_FE_REQUEST, reInviteFe);
    yield takeLatest(user.REMOVE_USER_TERRITORIES_REQUEST, removeUserTerritories);
    yield takeLatest(user.NEW_RESOURCE_ADD_REQUEST, newResourceFolder);
    yield takeLatest(user.NEW_RESOURCE_FILE_ADD_REQUEST, newResourceFile);
    yield takeLatest(user.GET_RESOURCE_REQUEST, getResource);
    yield takeLatest(user.GET_USER_INFO_REQUEST, getUserInfo);
    yield takeLatest(user.ADD_END_USER_REQUEST, addEndUser);
    yield takeLatest(user.DELETE_RESOURCE_REQUEST, deleteUserResource);
    yield takeEvery(user.CREATE_ROOT_FOLDER_REQUEST, createRootFolder);
    yield takeEvery(user.GET_USERS_RATECARD_REQUEST, getUsersRatecards);
    yield takeEvery(user.BANKS_LIST_REQUEST, getBanksList);
    yield takeLatest(user.PANCARD_VALIDATION_REQUEST, pancardValidation);
    yield takeLatest(user.BUSSINESS_PANCARD_VALIDATION_REQUEST, bussinessPancardValidation);
    yield takeLatest(user.GST_VALIDATION_REQUEST, gstValidation);
    yield takeLatest(user.USER_AADHAAR_VALIDATE_REQUEST, adhaarValidation);
    yield takeLatest(user.SUBMIT_AADHAAR_OTP_REQUEST, submitAdhaarOtp);
    yield takeLatest(user.BANK_VERIFICATION_REQUEST, bankVerification);
    yield takeLatest(user.ESIGN_TOKEN_REQUEST, initailizeEsign);
    yield takeLatest(user.GET_TECH_SUPPORT_USERS_DROPDOWN_REQUEST, getTechSupportUsers);
    yield takeLatest(user.GENERATE_OTP_REQUEST, generateOTP);
    yield takeLatest(user.VERIFY_OTP_REQUEST, verifyOTP);
    yield takeEvery(user.UPDATE_USER_PROFILE_BANK_DETAILS_REQUEST, updateUserProfileBankDetails);
    yield takeLatest(user.ADD_GST_REQUEST, addGst);
    yield takeLatest(user.GET_GST_LIST_REQUEST, getGstList);
    yield takeLatest(user.GET_GST_BY_ID_REQUEST, getGstById);
    yield takeLatest(user.UPDATE_GST_REQUEST, updateGstById);
    yield takeLatest(user.DELETE_GST_REQUEST, deleteGstById);
    yield takeLatest(user.LIST_TECH_ENGINEERS_REQUEST, getTechEngineers);
    yield takeLatest(user.GET_USER_PROFILE_DETAILS, getUserProfileDetails);
    yield takeLatest(user.VERIFY_USER_ADDRESS_REQUEST, verifyUserAddress);
    yield takeLatest(user.GET_FE_PROFILE_DETAILS_REQUEST, getFeProfileDetails);
    yield takeLatest(user.UPLOAD_USER_ID_PROOF_FRONT_BACK_REQUEST, uploadAddressProofBackAndFront);
    yield takeLatest(user.UPLOAD_USER_ID_PROOF_BACK_REQUEST, uploadAddressProofBack);
    yield takeEvery(user.BULK_INVITE_FILE_UPLOAD_REQUEST, bulkInviteUseFileUpload);
    yield takeLatest(user.ENABLE_USER_ROOM_REQUEST, enableRoom);
    yield takeLatest(user.DISABLE_USER_ROOM_REQUEST, disableRoom);
    yield takeLatest(user.GET_USER_CALL_STATUS_REQUEST, getUserCallStatus);
    yield takeLatest(user.GET_ENDUSERS_LIST_REQUEST, getEndUsers);
    yield takeLatest(user.CHECK_AND_UPDATE_KYC_STATUS, checkAndUpdateKycStatus);
}
