import React, { useCallback, useEffect } from "react";
import "./App.css";
import { getRoutes } from "./router/Routes";
import Toast from "../src/common/Toast";
import config from '../src/config/index';
import { RouterProvider } from "react-router-dom";
import { useSelector } from "react-redux";
import { gtag, install } from 'ga-gtag';
import { useDispatch } from "react-redux";
import { setSystemUIConfig } from "./actions/users";
import { UI_CONFIG_LOADING } from "./types/loading";
import { OfflineMessage } from "./common/OfflineMessage";
import { isMobileView } from "./pages/auth/components/NewScogoButton";



const script = document.createElement("script")
script.src = config.bankopen.paymenturl
script.async = true
document.body.appendChild(script)



function App() {

    const dispatch = useDispatch()

    const dynamicSystemUI = useCallback(async () => {
        if (!window.location.host.includes('localhost') && window.location.host !== config.ui4Domain) {
            const host = window.location.host;
            const configUrl = `https://s3.ap-south-1.amazonaws.com/whitelabel.configs.scogo.in/configs/${host}.json`
            dispatch({ type: UI_CONFIG_LOADING, payload: true });
            let response = await fetch(configUrl)
            response = await response.json()
            dispatch(setSystemUIConfig({ logoUrl: response.homepage.logoUrl, header: response.homepage.header, customer_id: response.homepage.customerId, support_track_attachment_title: response.homepage.support_track_attachment_title }));
            dispatch({ type: UI_CONFIG_LOADING, payload: false });
            let favicon = document.getElementById('favicon')
            favicon.href = response.favicon
        }
    }, [dispatch])


    useEffect(() => {
        dynamicSystemUI()
    }, [dynamicSystemUI])


    install(config.gTagId)
    gtag('config', config.gTagId);

    const { loggedUser } = useSelector(state => state.auth);
    const isSerUrl = window.location.host === config.serUrl;

    return (
        <>
            {isSerUrl ? <RenderSer /> :
                <>
                    <OfflineMessage />
                    <Toast />
                    {new Date().getDate() === 26 && new Date().getMonth() === 0 && <RenderAnnouncement />}
                    <RouterProvider router={getRoutes({ loggedUser })} />
                </>
            }
        </>
    );
}

export default App;


const RenderSer = () => {
    useEffect(() => {
        document.title = "Scogo SER";
    }, []);
    return <div className="h-[100vh] w-[100%] overflow-hidden">

        {isMobileView ? <>
            <div className="border-1 border-scogoprimary h-[100%] ">

                <iframe src="https://scogo-production-upload.s3.ap-south-1.amazonaws.com/media/popup-images/ser_product_brochure.pdf" width="100%" height="100%">
                </iframe>
            </div>
        </> : <object data="https://scogo-production-upload.s3.ap-south-1.amazonaws.com/media/popup-images/ser_product_brochure.pdf" type="application/pdf" width="100%" height="100%">
            <p>It appears you don't have a PDF plugin for this browser. You can <a href="https://scogo-production-upload.s3.ap-south-1.amazonaws.com/media/popup-images/ser_product_brochure.pdf">click here to download the PDF file.</a></p>
        </object>}

    </div >
}




export const RenderAnnouncement = () => {
    useEffect(() => {
        document.documentElement.style.setProperty('--announcement-bar-height', isMobileView ? '60px' : '35px');
    }, []);

    return (
        <div className={`md:px-10 px-4 text-[11px] bg-scogoprimary hover:underline flex justify-center items-center text-center text-white w-full overflow-hidden ${isMobileView ? 'h-[60px]' : 'h-[35px]'}`} style={{ position: 'fixed', top: '0', left: '0', right: '0', zIndex: '1000' }}>
            <a target="_blank" href="https://scogo.ai/" style={{ color: '#fff', textDecoration: 'none' }}>{`🚀 Launching SIA : Your Smart, Multilingual and Multichannel Customer Support AI Agent ! Launch Your Free AI Agent in Just 5 Seconds . Try It Now ➜`}</a>
        </div>
    )
};